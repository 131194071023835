<template>
  <div v-if="props.show" class="verify-phone-toast-wrapper">
    <div class="verify-phone-toast-container">
      <div class="toast-content">
        <img class="toast-icon" src="/images/info-icon.svg" alt="Info icon" />
        <div class="toast-description">
          <h1 class="title">Verify phone number</h1>
          <p class="text">Please verify your phone number</p>
        </div>
      </div>
      <div class="buttons">
        <button class="btn-primary" @click="onVerifyClick">Verify</button>
        <button class="btn-outline-primary" @click="hideToast">Later</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{ (e: 'hideToast'): void; (e: 'verifyClick'): void }>();

const hideToast = () => {
  emit('hideToast');
};

const onVerifyClick = () => {
  emit('verifyClick');
};
</script>

<style scoped lang="scss">
.verify-phone-toast-wrapper {
  margin: 0 auto;
  position: related;
  display: flex;
  justify-content: center;
}

.verify-phone-toast-container {
  width: calc(100% - 24px * 2);
  max-width: 1392px;
  padding: 20px;
  margin: 0 auto;

  position: fixed;
  bottom: 24px;
  z-index: 9999;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  color: white;
  font-family: Raleway;
  font-style: normal;
  border-radius: 8px;
  background: #1e8841;

  .toast-content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-grow: 1;

    .toast-icon {
      width: 24px;
      height: 24px;
    }

    .toast-description {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 4px;

      .title {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
      }

      .text {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .btn-primary,
    .btn-outline-primary {
      width: 139px;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }

  @media screen and (max-width: 425px) {
    width: calc(100% - 16px * 2);
    padding: 16px 20px;
    left: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .toast-content {
      flex-direction: column;
      align-items: center;
      gap: 12px;

      .toast-description {
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 365px) {
    .buttons {
      a,
      .btn-primary,
      .btn-outline-primary {
        width: 100%;
      }
    }
  }
}
</style>
